<template>
  <div style="width: 100vw; height: 100vh">
    <div
      v-show="!ofdObj"
      class="upload-icon"
      @click="uploadFile"
      style="margin: 200px auto"
    >
      打开 OFD
      <input type="file" ref="file" class="hidden" @change="fileChanged" />
    </div>

    <header
      v-show="ofdObj"
      style="
        background: #f5f5f5;
        display: flex;
        height: 42px;
        padding: 0px 10px;
        align-items: center;
      "
    >
      <div class="upload-icon" @click="uploadFile">
        打开 OFD
        <input type="file" ref="file" class="hidden" @change="fileChanged" />
      </div>

      <div style="display: flex; flex: 1; align-items: center" v-if="ofdObj">
        <div class="scale-icon" style="margin-left: 10px" @click="plus">➕</div>
        <div class="scale-icon" @click="minus">➖</div>
        <div class="scale-icon">⏮️</div>
        <div class="scale-icon" @click="prePage">◀️</div>
        <div class="scale-icon">{{ pageIndex }}/{{ pageCount }}</div>
        <div class="scale-icon" @click="nextPage">▶️</div>
        <div class="scale-icon" @click="lastPage">⏭️</div>
      </div>
      <div class="scale-icon" @click="print">🖨️</div>
    </header>
    <main v-show="ofdObj" style="height: auto; padding: 0; background: #808080">
      <div
        class="main-section"
        id="content"
        ref="contentDiv"
        @mousewheel="scrool"
      ></div>
    </main>
    <div
      class="SealContainer"
      id="sealInfoDiv"
      hidden="hidden"
      ref="sealInfoDiv"
    >
      <div class="SealContainer mask" @click="closeSealInfoDialog"></div>
      <div class="SealContainer-layout">
        <div class="SealContainer-content">
          <p class="content-title">签章信息</p>
          <div class="subcontent">
            <span class="title">签章人</span>
            <span class="value" id="spSigner">[无效的签章结构]</span>
          </div>
          <div class="subcontent">
            <span class="title">签章提供者</span>
            <span class="value" id="spProvider">[无效的签章结构]</span>
          </div>
          <div class="subcontent">
            <span class="title">原文摘要值</span>
            <span class="value" id="spHashedValue">[无效的签章结构]</span>
          </div>
          <div class="subcontent">
            <span class="title">签名值</span>
            <span class="value" id="spSignedValue">[无效的签章结构]</span>
          </div>
          <div class="subcontent">
            <span class="title">签名算法</span>
            <span class="value" id="spSignMethod">[无效的签章结构]</span>
          </div>
          <div class="subcontent">
            <span class="title">版本号</span>
            <span class="value" id="spVersion">[无效的签章结构]</span>
          </div>
          <div class="subcontent">
            <span class="title">验签结果</span>
            <span class="value" id="VerifyRet">[无效的签章结构]</span>
          </div>

          <p class="content-title">印章信息</p>
          <div class="subcontent">
            <span class="title">印章标识</span>
            <span class="value" id="spSealID">[无效的签章结构]</span>
          </div>
          <div class="subcontent">
            <span class="title">印章名称</span>
            <span class="value" id="spSealName">[无效的签章结构]</span>
          </div>
          <div class="subcontent">
            <span class="title">印章类型</span>
            <span class="value" id="spSealType">[无效的签章结构]</span>
          </div>
          <div class="subcontent">
            <span class="title">有效时间</span>
            <span class="value" id="spSealAuthTime">[无效的签章结构]</span>
          </div>
          <div class="subcontent">
            <span class="title">制章日期</span>
            <span class="value" id="spSealMakeTime">[无效的签章结构]</span>
          </div>
          <div class="subcontent">
            <span class="title">印章版本</span>
            <span class="value" id="spSealVersion">[无效的签章结构]</span>
          </div>
        </div>
        <input
          style="position: absolute; right: 1%; top: 1%"
          type="button"
          name=""
          id=""
          value="X"
          @click="closeSealInfoDialog()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  parseOfdDocument,
  renderOfd,
  renderOfdByScale,
  digestCheck,
  getPageScale,
  setPageScale
} from "@/utils/ofd/ofd"

export default {
  name: "HelloWorld",
  data() {
    return {
      ofdBase64: null,
      loading: false,
      pageIndex: 1,
      pageCount: 0,
      scale: 0,
      ofdObj: null,
      screenWidth: document.body.clientWidth
    }
  },

  created() {
    this.file = null
  },

  mounted() {
    let that = this
    this.$refs.contentDiv.addEventListener("scroll", this.scrool)
    window.onresize = () => {
      return (() => {
        that.screenWidth = document.body.clientWidth
        const divs = renderOfd(that.screenWidth, that.ofdObj)
        that.displayOfdDiv(divs)
      })()
    }
  },

  methods: {
    print() {
      window.print()
    },
    scrool() {
      let scrolled =
        this.$refs.contentDiv.firstElementChild?.getBoundingClientRect()?.top -
        60
      let top = 0
      let index = 0
      for (let i = 0; i < this.$refs.contentDiv.childElementCount; i++) {
        top +=
          Math.abs(
            this.$refs.contentDiv.children
              .item(i)
              ?.style.height.replace("px", "")
          ) +
          Math.abs(
            this.$refs.contentDiv.children
              .item(i)
              ?.style.marginBottom.replace("px", "")
          )
        if (Math.abs(scrolled) < top) {
          index = i
          break
        }
      }
      this.pageIndex = index + 1
    },

    closeSealInfoDialog() {
      this.$refs.sealInfoDiv.setAttribute("style", "display: none")
      document.getElementById("spSigner").innerText = "[无效的签章结构]"
      document.getElementById("spProvider").innerText = "[无效的签章结构]"
      document.getElementById("spHashedValue").innerText = "[无效的签章结构]"
      document.getElementById("spSignedValue").innerText = "[无效的签章结构]"
      document.getElementById("spSignMethod").innerText = "[无效的签章结构]"
      document.getElementById("spSealID").innerText = "[无效的签章结构]"
      document.getElementById("spSealName").innerText = "[无效的签章结构]"
      document.getElementById("spSealType").innerText = "[无效的签章结构]"
      document.getElementById("spSealAuthTime").innerText = "[无效的签章结构]"
      document.getElementById("spSealMakeTime").innerText = "[无效的签章结构]"
      document.getElementById("spSealVersion").innerText = "[无效的签章结构]"
      document.getElementById("spVersion").innerText = "[无效的签章结构]"
      document.getElementById("VerifyRet").innerText = "[无效的签章结构]"
    },

    plus() {
      setPageScale(++this.scale)
      const divs = renderOfdByScale(this.ofdObj)
      this.displayOfdDiv(divs)
    },

    minus() {
      setPageScale(--this.scale)
      const divs = renderOfdByScale(this.ofdObj)
      this.displayOfdDiv(divs)
    },

    prePage() {
      let contentDiv = document.getElementById("content")
      let ele = contentDiv.children.item(this.pageIndex - 2)
      ele?.scrollIntoView(true)
      ele ? (this.pageIndex = this.pageIndex - 1) : ""
    },

    firstPage() {
      let contentDiv = document.getElementById("content")
      let ele = contentDiv.firstElementChild
      ele?.scrollIntoView(true)
      ele ? (this.pageIndex = 1) : ""
    },

    nextPage() {
      let contentDiv = document.getElementById("content")
      let ele = contentDiv.children.item(this.pageIndex)
      ele?.scrollIntoView(true)
      ele ? ++this.pageIndex : ""
    },

    lastPage() {
      let contentDiv = document.getElementById("content")
      let ele = contentDiv.lastElementChild
      ele?.scrollIntoView(true)
      ele ? (this.pageIndex = contentDiv.childElementCount) : ""
    },
    uploadFile() {
      this.file = null
      this.$refs.file.click()
    },
    fileChanged() {
      this.file = this.$refs.file.files[0]
      let ext = this.file.name.replace(/.+\./, "")
      if (["ofd", "pdf"].indexOf(ext) === -1) {
        this.$alert("error", "仅支持ofd类型", {
          confirmButtonText: "确定",
          callback: action => {
            this.$message({
              type: "info",
              message: `action: ${action}`
            })
          }
        })
        return
      }
      if (this.file.size > 100 * 1024 * 1024) {
        this.$alert("error", "文件大小需 < 100M", {
          confirmButtonText: "确定",
          callback: action => {
            this.$message({
              type: "info",
              message: `action: ${action}`
            })
          }
        })
        return
      }
      let that = this
      let reader = new FileReader()
      reader.readAsDataURL(this.file)
      reader.onload = function (e) {
        that.ofdBase64 = e.target.result.split(",")[1]
      }
      this.getOfdDocumentObj(this.file, this.screenWidth)
      this.$refs.file.value = null
    },

    getOfdDocumentObj(file, screenWidth) {
      let that = this
      let t = new Date().getTime()
      this.loading = true
      parseOfdDocument({
        ofd: file,
        success(res) {
          console.log(res)
          let t1 = new Date().getTime()
          console.log("解析ofd", t1 - t)
          that.ofdObj = res[0]
          that.pageCount = res[0].pages.length
          const divs = renderOfd(screenWidth, res[0])
          let t2 = new Date().getTime()
          console.log("xml转svg", t2 - t1)
          that.displayOfdDiv(divs)
          let t3 = new Date().getTime()
          console.log("svg渲染到页面", t3 - t2)
          that.loading = false
        },
        fail(error) {
          that.loading = false
          that.$alert("OFD打开失败", error, {
            confirmButtonText: "确定",
            callback: action => {
              this.$message({
                type: "info",
                message: `action: ${action}`
              })
            }
          })
        }
      })
    },

    displayOfdDiv(divs) {
      this.scale = getPageScale()
      let contentDiv = document.getElementById("content")
      contentDiv.innerHTML = ""
      for (const div of divs) {
        contentDiv.appendChild(div)
      }
      for (let ele of document.getElementsByName("seal_img_div")) {
        this.addEventOnSealDiv(
          ele,
          JSON.parse(ele.dataset.sesSignature),
          JSON.parse(ele.dataset.signedInfo)
        )
      }
    },

    addEventOnSealDiv(div, SES_Signature, signedInfo) {
      try {
        global.HashRet = null
        global.VerifyRet = signedInfo.VerifyRet
        div.addEventListener("click", function () {
          document.getElementById("sealInfoDiv").hidden = false
          document
            .getElementById("sealInfoDiv")
            .setAttribute(
              "style",
              "display:flex;align-items: center;justify-content: center;"
            )
          if (SES_Signature.realVersion < 4) {
            document.getElementById("spSigner").innerText =
              SES_Signature.toSign.cert["commonName"]
            document.getElementById("spProvider").innerText =
              signedInfo.Provider["@_ProviderName"]
            document.getElementById("spHashedValue").innerText =
              SES_Signature.toSign.dataHash.replace(/\n/g, "")
            document.getElementById("spSignedValue").innerText =
              SES_Signature.signature.replace(/\n/g, "")
            document.getElementById("spSignMethod").innerText =
              SES_Signature.toSign.signatureAlgorithm.replace(/\n/g, "")
            document.getElementById("spSealID").innerText =
              SES_Signature.toSign.eseal.esealInfo.esID
            document.getElementById("spSealName").innerText =
              SES_Signature.toSign.eseal.esealInfo.property.name
            document.getElementById("spSealType").innerText =
              SES_Signature.toSign.eseal.esealInfo.property.type
            document.getElementById("spSealAuthTime").innerText =
              "从 " +
              SES_Signature.toSign.eseal.esealInfo.property.validStart +
              " 到 " +
              SES_Signature.toSign.eseal.esealInfo.property.validEnd
            document.getElementById("spSealMakeTime").innerText =
              SES_Signature.toSign.eseal.esealInfo.property.createDate
            document.getElementById("spSealVersion").innerText =
              SES_Signature.toSign.eseal.esealInfo.header.version
          } else {
            document.getElementById("spSigner").innerText =
              SES_Signature.cert["commonName"]
            document.getElementById("spProvider").innerText =
              signedInfo.Provider["@_ProviderName"]
            document.getElementById("spHashedValue").innerText =
              SES_Signature.toSign.dataHash.replace(/\n/g, "")
            document.getElementById("spSignedValue").innerText =
              SES_Signature.signature.replace(/\n/g, "")
            document.getElementById("spSignMethod").innerText =
              SES_Signature.signatureAlgID.replace(/\n/g, "")
            document.getElementById("spSealID").innerText =
              SES_Signature.toSign.eseal.esealInfo.esID
            document.getElementById("spSealName").innerText =
              SES_Signature.toSign.eseal.esealInfo.property.name
            document.getElementById("spSealType").innerText =
              SES_Signature.toSign.eseal.esealInfo.property.type
            document.getElementById("spSealAuthTime").innerText =
              "从 " +
              SES_Signature.toSign.eseal.esealInfo.property.validStart +
              " 到 " +
              SES_Signature.toSign.eseal.esealInfo.property.validEnd
            document.getElementById("spSealMakeTime").innerText =
              SES_Signature.toSign.eseal.esealInfo.property.createDate
            document.getElementById("spSealVersion").innerText =
              SES_Signature.toSign.eseal.esealInfo.header.version
          }
          document.getElementById("spVersion").innerText =
            SES_Signature.toSign.version
          document.getElementById("VerifyRet").innerText =
            "文件摘要值后台验证中，请稍等... " +
            (global.VerifyRet ? "签名值验证成功" : "签名值验证失败")
          if (
            global.HashRet == null ||
            global.HashRet == undefined ||
            Object.keys(global.HashRet).length <= 0
          ) {
            setTimeout(function () {
              const signRetStr = global.VerifyRet
                ? "签名值验证成功"
                : "签名值验证失败"
              global.HashRet = digestCheck(
                global.toBeChecked.get(signedInfo.signatureID)
              )
              const hashRetStr = global.HashRet
                ? "文件摘要值验证成功"
                : "文件摘要值验证失败"
              document.getElementById("VerifyRet").innerText =
                hashRetStr + " " + signRetStr
            }, 1000)
          }
        })
      } catch (e) {
        console.log(e)
      }
      if (!global.VerifyRet) {
        div.setAttribute("class", "gray")
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.upload-icon {
  max-width: 200px;
  flex-shrink: 0;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 20px;
  background: linear-gradient(to right, #ff416c, #ff4b2b);
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  color: white;
  margin: 1px;
  transition: all 0.3s ease;
}
.upload-icon:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.scale-icon {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 28px;
  background-color: #f5f5f5;
  border-radius: 1px;
  text-align: center;
  padding: 2px;
}

.hidden {
  display: none !important;
}

.SealContainer {
  z-index: 99999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.SealContainer .mask {
  z-index: 1;
  background: #000000;
  opacity: 0.3;
}

.content-title {
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid rgb(59, 95, 232);
  color: rgb(59, 95, 232);
  margin-top: 10px;
}

.SealContainer-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
}

.SealContainer-layout {
  position: relative;
  width: 60%;
  height: 80vh;
  overflow-y: auto;
  background: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
}

.subcontent {
  width: 80%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 10px;
  font-family: simsun;
}

.subcontent .title {
  font-weight: 600;
}

.subcontent .value {
  font-weight: 400;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.main-section {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #808080;
  overflow: hidden;
}

@media (max-width: 767px) {
  .SealContainer-layout {
    position: relative;
    width: 90%;
    height: 90vh;
    overflow-y: auto;
    background: white;
    z-index: 100;
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
  }

  .subcontent {
    width: 95%;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 10px;
    font-family: simsun;
  }

  .main-section {
    padding-top: 20px;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    overflow: hidden;
  }
}

@media print {
  header,
  .upload-icon,
  .scale-icon,
  .SealContainer {
    display: none;
    border: none;
    background: #fff;
  }

  main {
    background: rgba(0, 0, 0, 0);
  }
}
</style>
